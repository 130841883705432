import './App.css';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import MainPage from './Pages/main_page';
import {createTheme, ThemeProvider} from '@mui/material';
import Ajuda from './Pages/ajuda';
import PoliticaDePrivacidade from './Pages/politica_de_privacidade';
import CodigoDeConduta from './Pages/codigo_de_conduta';
import TermosDeUso from './Pages/termos_de_uso';
import ResetPassword from './Pages/resetpassword';
import Confirmemail from './Pages/confirmemail';
import Redirect from './Pages/redirect';
import firebase from 'firebase/compat/app';

const theme = createTheme({
  typography: {
    fontFamily: ['Nunito'].join(','),
  },
});

function App() {
  if (firebase.apps.length === 0) {
    firebase.initializeApp({
      apiKey: 'AIzaSyA7d6k4NU7QLZpc142vDLklI5qDw1AwE0c',
      authDomain: 'mercasadelivery.firebaseapp.com',
      databaseURL: 'https://mercasadelivery.firebaseio.com',
      projectId: 'mercasadelivery',
      storageBucket: 'mercasadelivery.appspot.com',
      messagingSenderId: '1075142419931',
      appId: '1:1075142419931:web:ef11de93606bcae5ccf3fe',
    });
  }

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/ajuda" element={<Ajuda />} />
          <Route
            path="/politica_de_privacidade"
            element={<PoliticaDePrivacidade />}
          />
          <Route path="/codigo_de_conduta" element={<CodigoDeConduta />} />
          <Route path="/termos_de_uso" element={<TermosDeUso />} />
          <Route path="/resetpassword" element={<ResetPassword />} />
          <Route path="/confirmemail" element={<Confirmemail />} />
          <Route path="/app/*" element={<Redirect />} />
          <Route path="/*" element={<MainPage />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
