import {Box} from '@mui/system';
import React, {useEffect, useLayoutEffect, useState} from 'react';
import {useMediaQuery} from 'react-responsive';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import logo from '../../Assets/logo.png';
import AlertSending from '../../Components/AlertSending';
import {getAuth, applyActionCode} from 'firebase/auth';
import {Check} from '@mui/icons-material';

const Confirmemail = () => {
  const isSmall = useMediaQuery({query: '(max-width: 750.98px)'});
  const isVerySmall = useMediaQuery({query: '(max-width:450.00px)'});
  const [isExpired, setIsExpired] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [isDone, setDone] = useState(false);

  const auth = getAuth();
  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }
  const [width, height] = useWindowSize();

  function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  useEffect(() => {
    applyActionCode(auth, getParameterByName('oobCode'))
      .then(resp => {
        setDone(true);
        setIsReady(true);
        setIsExpired(false);
        console.debug(resp);
      })
      .catch(error => {
        setDone(true);
        setIsReady(true);
        setIsExpired(true);
        console.log('Error to verify: ', error);
      });
  }, []);

  return (
    <div
      style={{
        width: width,
        height: height,
        background: 'linear-gradient(#06448e, #7c53e3, #b553e3)',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
      }}
    >
      {isReady ? (
        <Box
          boxShadow={3}
          borderRadius={1}
          style={{
            background: 'rgba(255, 255, 255, 0.725)',
            marginLeft: isVerySmall || isSmall ? 20 : 30,
            marginRight: isVerySmall || isSmall ? 20 : 30,
            padding: isVerySmall || isSmall ? 15 : 20,
          }}
        >
          {!isExpired ? (
            <div>
              {isDone ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: isVerySmall || isSmall ? '100%' : 275,
                    justifyContent: 'center',
                  }}
                >
                  <img
                    src={logo}
                    style={{
                      width: 100,
                      height: 50,
                      background: '#06448e',
                      paddingLeft: 20,
                      paddingRight: 20,
                      paddingTop: 10,
                      paddingBottom: 10,
                      borderRadius: 5,
                    }}
                  />
                  <Check
                    style={{color: '#52d869', fontSize: 50, marginTop: 25}}
                  />
                  <p
                    style={{
                      fontSize: 20,
                      fontWeight: 'bold',
                      textAlign: 'center',
                    }}
                  >
                    Email confirmado com sucesso!
                  </p>
                </div>
              ) : null}
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: isVerySmall || isSmall ? '100%' : 275,
                justifyContent: 'center',
              }}
            >
              <img
                src={logo}
                style={{
                  width: 100,
                  height: 50,
                  background: '#06448e',
                  paddingLeft: 20,
                  paddingRight: 20,
                  paddingTop: 10,
                  paddingBottom: 10,
                  borderRadius: 5,
                }}
              />
              <InfoOutlinedIcon
                style={{color: '#ff6600', fontSize: 50, marginTop: 20}}
              />
              <p
                style={{fontSize: 20, fontWeight: 'bold', textAlign: 'center'}}
              >
                Sua confirmação de email expirou
              </p>
              <p style={{fontSize: 20, textAlign: 'center'}}>
                Envie uma nova requisição para poder confirmar o email.
              </p>
            </div>
          )}
        </Box>
      ) : null}

      <AlertSending
        bopen={isSending}
        handleClose={() => setIsSending(false)}
        handleNotiStack
        style={{
          top: 0,
          position: 'absolute',
          zIndex: 3,
        }}
      />
    </div>
  );
};

export default Confirmemail;
