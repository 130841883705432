import {CircularProgress, Dialog} from '@mui/material';
import React from 'react';

import {useMediaQuery} from 'react-responsive';

// eslint-disable-next-line react/prop-types
const AlertSending = ({bopen, handleClose}) => {
  const isVerySmall = useMediaQuery({query: '(max-width:450.00px)'});

  function close() {
    handleClose();
  }

  return (
    <div>
      <Dialog
        onClose={() => close()}
        style={{
          marginLeft: isVerySmall ? -20 : 0,
          marginRight: isVerySmall ? -20 : 0,
          paddingLeft: 0,
        }}
        aria-labelledby="customized-dialog-title"
        open={bopen}
      >
        <div>
          <div
            style={{
              display: 'flex',
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center',
              height: 100,
              marginLeft: 20,
              marginRight: 20,
            }}
          >
            <CircularProgress />
            <p1 style={{marginLeft: 20}}>Salvando...</p1>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default AlertSending;
